import { createApp } from 'vue'
import App from './views/Login.vue'
import store from './store/shared-store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import dayjs from 'dayjs'
import 'dayjs/locale/fi';
import "alertifyjs/build/css/alertify.min.css";

import qsStringify from 'qs-stringify';
window.stringify = qsStringify;

import axios from "axios";
window.axios = axios;

import alertify from "alertifyjs"
window.alertify = alertify;

import Lodash from 'lodash'

var customParseFormat = require('dayjs/plugin/customParseFormat')

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.locale('fi');

window.$dayjs = dayjs;

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faImages, faBars, faClock, faEnvelope, faChartPie, faFileAlt, faCheckCircle, faMap, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faImages, faBars, faClock, faEnvelope, faChartPie, faFileAlt, faCheckCircle, faMap, faWindowClose)

const WiseApp = createApp(App).use(store);

WiseApp.component('font-awesome-icon', FontAwesomeIcon)
WiseApp.config.globalProperties.$dayjs = dayjs;
WiseApp.config.globalProperties.$_ = Lodash;
WiseApp.config.globalProperties.$vm = window.vm;
WiseApp.config.globalProperties.$alertify = window.alertify;

WiseApp.mount('#login-app');
