import {createStore} from 'vuex'

export default createStore({
    mutations: {},
    actions: {
        get(context, config) {
            if (!config) {
                window.alertify.error("get-metodin konfiguraatio puuttuu!");
                return false;
            }

            var parameters = config && config.parameters ? window.stringify(config.parameters) : "";
            var route = window.vm.basePath + config.route + "?" + parameters;

            if (!route) {
                window.alertify.error("Reititys puuttuu!");
                return false;
            }

            return window.axios.get(route)
                .then(response => {
                    return response.data;
                }).catch(error => {
                    if(error?.response?.status === 401) {
                        window.alertify.error("Istuntosi on vanhentunut. Ohjataan kirjautumiseen...", 3);
                        setTimeout(() => {
                            window.location = window.vm.basePath;
                        }, 4000)
                    }
                    console.log(error);
                    throw error;
                });
        },
        post(context, config) {
            if (!config) {
                window.alertify.error("post-metodin konfiguraatio puuttuu!");
                return false;
            }

            var route = window.vm.basePath + config.route;

            if (!route) {
                window.alertify.error("Reititys puuttuu!");
                return false;
            }

            return window.axios.post(route, config.parameters)
                .then(response => {
                    return response.data;
                }).catch(error => {
                    if(error?.response?.status === 401) {
                        window.alertify.error("Istuntosi on vanhentunut. Ohjataan kirjautumiseen...", 3);
                        setTimeout(() => {
                            window.location = window.vm.basePath;
                        }, 4000)
                    }
                    console.log(error.response);
                    if(error?.response?.data?.Message) {
                        window.alertify.error("Tapahtui virhe: " + error.response.data.Message, 5);
                    }
                    console.log(error);
                    throw error;
                });
        },

    },
    modules: {}
})
